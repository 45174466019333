import React, { cloneElement, forwardRef, useEffect, useState } from "react";
import { MdExplore, MdLanguage } from "react-icons/md";
import Link from "next/link";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useRouter } from "next/router";
import {
  Avatar,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Box,
  Flex,
  Stack,
  VStack,
  Text,
  useDisclosure,
  useColorMode,
  useColorModeValue,
  Center,
  Tooltip,
  Portal,
  Divider,
} from "design-system";
import { MdAccountCircle, MdAdd, MdExitToApp, MdSearch } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

import { useMyWorlds } from "~/hooks/useMyWorlds";
import { useFlag } from "~/hooks/useFlag";
import { FLAGS } from "~/constants/flags";
import useAuth from "~/hooks/useAuth";
import useUser from "~/hooks/useUser";
import { NextExhibitModal } from "../modals/NextExhibitModal";
import { FirstExhibitUnauthenticatedModal } from "../modals/FirstExhibitUnauthenticatedModal";
import { extractEmoji } from "~/utils/extractEmoji";

const DockItem = forwardRef(function DockItem(
  { children, title, href, isDragging, colorScheme: c = "gray", ...props },
  ref
) {
  const router = useRouter();
  const isActive = !isDragging && router.asPath.startsWith(href);

  const bg = useColorModeValue(`${c}.100`, `${c}.800`);
  const color = useColorModeValue(`${c}.600`, `${c}.200`);

  return (
    <Box ref={ref} position="relative" {...props}>
      <Box
        position="absolute"
        mt={4}
        top={0}
        bottom={0}
        left={-3}
        bg="brand.500"
        width={1}
        height={4}
        borderTopRightRadius="full"
        borderBottomRightRadius="full"
        visibility={isActive ? "visible" : "hidden"}
      />
      <Tooltip label={title} placement="right">
        <Box>
          <Link href={href} passHref>
            <Flex
              as="a"
              borderRadius="full"
              w={12}
              h={12}
              overflow="hidden"
              transition="all 0.3s ease"
              align="center"
              justify="center"
              fontSize="3xl"
              bg={bg}
              color={color}
              _hover={{
                color: "white",
                bg: "brand.600",
              }}
            >
              {children}
            </Flex>
          </Link>
        </Box>
      </Tooltip>
    </Box>
  );
});

DockItem.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  href: PropTypes.string,
  isDragging: PropTypes.bool,
  colorScheme: PropTypes.string,
};

const circlePath = `
M 48 24
C 48 37.2548 37.2548 48 24 48
C 10.7452 48 0 37.2548 0 24
C 0 10.7452 10.7452 0 24 0
C 37.2548 0 48 10.7452 48 24
Z
`;

const squirclePath = `
M 48 24
C 48 48 48 48 24 48
C 0 48 0 48 0 24
C 0 0 0 0 24 0
C 48 0 48 0 48 24
Z
`;

const maskVariants = {
  hover: {
    d: squirclePath,
  },
  initial: {
    d: circlePath,
  },
  selected: {
    d: squirclePath,
  },
};

const ExhibitDockItem = forwardRef(function ExhibitDockItem(
  {
    children = null,
    size = 48,
    isSelected = false,
    href,
    title,
    id,
    colorScheme: c = "gray",
    ...props
  },
  ref
) {
  const child = cloneElement(children, {
    width: "100%",
    height: "100%",
    "aria-hidden": "true",
  });

  const bg = useColorModeValue(`${c}.100`, `${c}.700`);
  const color = useColorModeValue(`${c}.700`, `${c}.100`);

  return (
    <motion.div
      ref={ref}
      initial="initial"
      whileHover="hover"
      animate={isSelected ? "selected" : "initial"}
      cursor={isSelected ? "unset" : "pointer"}
      {...props}
    >
      <Tooltip label={title} placement="right">
        <Box>
          <Link href={href} passHref>
            <Box as="a">
              <svg
                height={size}
                width={size}
                viewBox="0 0 48 48"
                overflow="visible"
              >
                <defs>
                  <g id={`${id}-blob_mask`}>
                    <motion.path variants={maskVariants} />
                  </g>
                </defs>
                <mask
                  id={`${id}`}
                  fill="black"
                  x="0"
                  y="0"
                  width="48"
                  height="48"
                >
                  <use href={`#${id}-blob_mask`} fill="white" />
                </mask>
                <foreignObject
                  mask={`url(#${id})`}
                  x="0"
                  y="0"
                  width="48"
                  height="48"
                >
                  <Box
                    width="100%"
                    height="100%"
                    role="treeitem"
                    tabIndex="-1"
                    href={href}
                    aria-label={title}
                    bg={bg}
                    color={color}
                  >
                    {child}
                  </Box>
                </foreignObject>
              </svg>
            </Box>
          </Link>
        </Box>
      </Tooltip>
    </motion.div>
  );
});

ExhibitDockItem.propTypes = {
  size: PropTypes.number,
  children: PropTypes.node,
  title: PropTypes.string,
  id: PropTypes.string,
  href: PropTypes.string,
  isSelected: PropTypes.bool,
  colorScheme: PropTypes.string,
};

function Exhibit({ exhibit, index }) {
  const router = useRouter();
  return (
    <Draggable draggableId={exhibit.id} index={index}>
      {(provided, snapshot) => (
        <ExhibitDockItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          title={exhibit.title}
          id={exhibit.id}
          isSelected={
            !snapshot.isDragging &&
            router.asPath.startsWith(`/exhibit/${exhibit.id}`)
          }
          href={`/exhibit/${exhibit.id}/admin`}
          colorScheme={exhibit.theme.color}
        >
          <Center>
            {exhibit.title ? (
              <Text fontWeight="semibold">
                {extractEmoji(exhibit.title).emojis[0] ??
                  exhibit.title
                    .split(" ")
                    .slice(0, 2)
                    .map((word) => word[0])
                    .join("")}
              </Text>
            ) : (
              <FaBuilding size="20px" />
            )}
          </Center>
        </ExhibitDockItem>
      )}
    </Draggable>
  );
}

Exhibit.propTypes = { exhibit: PropTypes.object, index: PropTypes.number };

function ExhibitList({ exhibits }) {
  return (
    <Stack as="ul" spacing={3}>
      {exhibits?.map((exhibit, index) => (
        <Exhibit key={exhibit.id} exhibit={exhibit} index={index} />
      ))}
    </Stack>
  );
}

ExhibitList.propTypes = { exhibits: PropTypes.array };

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function Dock() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { currentUser, login, logout } = useAuth();
  const { exhibits } = useUser(currentUser?.id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [state, setState] = useState([]);

  useEffect(() => {
    if (!exhibits) return;
    setState(exhibits);
  }, [exhibits]);

  const { myWorlds } = useMyWorlds();
  const isMyWorldPageEnabled = useFlag(FLAGS.MY_WORLD_PAGE);
  const isWorldsExperimentalEnabled = useFlag(FLAGS.WORLDS_EXPERIMENTAL);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newState = reorder(
      state,
      result.source.index,
      result.destination.index
    );

    setState(newState);
  }

  const bg = useColorModeValue("white", "gray.900");

  return (
    <Flex
      as="nav"
      direction="column"
      h="100%"
      px={3}
      py={4}
      bg={bg}
      borderRightWidth={1}
      overflowY="auto"
      justifyContent="space-between"
    >
      <VStack spacing={3}>
        <DockItem title="Explore" href="/explore">
          <MdExplore />
        </DockItem>

        <DockItem title="Search" href="/search">
          <MdSearch />
        </DockItem>

        {isWorldsExperimentalEnabled &&
          isMyWorldPageEnabled &&
          myWorlds?.length > 0 && (
            <DockItem title="Worlds" href="/worlds">
              <MdLanguage />
            </DockItem>
          )}

        <Divider />

        {state && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="exhibits">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <ExhibitList exhibits={state} />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}

        <Tooltip label="Create Exhibit" placement="right">
          <Flex
            as="button"
            onClick={onOpen}
            href="#"
            borderRadius="full"
            minW={12}
            minH={12}
            overflow="hidden"
            transition="all 0.3s ease"
            color="gray.500"
            border="2px dashed"
            borderColor="gray.300"
            align="center"
            justify="center"
            fontSize="3xl"
            _hover={{
              color: "gray.800",
              bg: "gray.300",
            }}
          >
            <MdAdd />
          </Flex>
        </Tooltip>

        {currentUser ? (
          <NextExhibitModal isOpen={isOpen} onClose={onClose} />
        ) : (
          <FirstExhibitUnauthenticatedModal isOpen={isOpen} onClose={onClose} />
        )}
      </VStack>

      <Box mt={3}>
        {currentUser ? (
          <Menu>
            <MenuButton>
              <Avatar
                name={`${currentUser?.firstName} ${currentUser?.lastName}`}
                src={currentUser?.profilePictureUrl}
              />
            </MenuButton>
            <Portal>
              <MenuList>
                <Stack spacing={0} px={3}>
                  <Text fontSize="sm" fontWeight="medium">
                    Treasured
                  </Text>
                  <Text fontSize="sm" fontWeight="medium" color="gray.500">
                    {process.env.NODE_ENV === "production"
                      ? `Build ${process.env.RELEASE || "v0.0.0"}`
                      : "Development Build"}
                  </Text>
                </Stack>
                <MenuDivider />
                <Link href="/help" passHref>
                  <MenuItem as="a">Help & Feedback</MenuItem>
                </Link>
                <Link href="/settings" passHref>
                  <MenuItem as="a">Settings</MenuItem>
                </Link>
                <MenuItem onClick={toggleColorMode}>
                  Toggle {colorMode === "light" ? "dark" : "light"} mode
                </MenuItem>
                <MenuDivider />
                <MenuItem icon={<MdExitToApp size="20px" />} onClick={logout}>
                  Logout
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        ) : (
          <Flex
            as="button"
            onClick={() => login()}
            borderRadius="full"
            w={12}
            h={12}
            overflow="hidden"
            transition="all 0.3s ease"
            align="center"
            justify="center"
            fontSize="3xl"
            bg="gray.800"
            color="brand.200"
            _hover={{
              color: "gray.800",
              bg: "brand.200",
            }}
          >
            <MdAccountCircle />
          </Flex>
        )}
      </Box>
    </Flex>
  );
}

Dock.propTypes = {};

export default Dock;
