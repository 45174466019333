import React from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalFooter,
  VStack,
  Heading,
  Text,
  Button,
} from "design-system";
import { MdCheck } from "react-icons/md";
import PropTypes from "prop-types";
import useAuth from "~/hooks/useAuth";

export function FirstExhibitUnauthenticatedModal({ isOpen, onClose }) {
  const { login } = useAuth();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} py={4} textAlign="center">
            <Illustration />
            <Heading as="h2" size="md">
              Ready for your first exhibit?
            </Heading>
            <Text>
              We&apos;re super excited to get you started as a curator on
              Treasured! We just have one small favour to ask. To help save your
              content in case you want to take a break, we ask that you{" "}
              <strong>create an account</strong> first. Sound okay to you?
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            No Thanks
          </Button>
          <Button
            onClick={() => login()}
            colorScheme="green"
            rightIcon={<MdCheck />}
          >
            Yup!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

FirstExhibitUnauthenticatedModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

function Illustration() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 808 607.018"
    >
      <path fill="#ccc" d="M203.7 186.04H608.94V188.04H203.7z"></path>
      <path fill="#ccc" d="M405 1.002H407V187.038H405z"></path>
      <path fill="#ccc" d="M235.35 93.02H576.65V95.02H235.35z"></path>
      <path
        fill="#2f2e41"
        d="M666.54 476.833a41.97 41.97 0 01-10.39 27.66c-.6.69-1.23 1.35-1.87 2h-59.5c-.64-.65-1.27-1.31-1.87-2a41.846 41.846 0 01-8.59-15.45s.01 0 0-.01a42.017 42.017 0 01-1.8-12.2 40.808 40.808 0 01.31-5.09 42.013 42.013 0 0183.39-.11 42.463 42.463 0 01.32 5.2z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#2f2e41"
        d="M375.58 360.002L370.44 360.002 374.43 358.002 374.55 357.942 374.58 358.002 375.58 360.002z"
      ></path>
      <path
        fill="#2f2e41"
        d="M410.61 360.002L405.47 360.002 406.47 358.002 406.5 357.942 406.62 358.002 410.61 360.002z"
      ></path>
      <circle cx="425.588" cy="320.067" r="14.359" fill="#fff"></circle>
      <ellipse
        cx="616.686"
        cy="469.411"
        fill="#3f3d56"
        rx="4.766"
        ry="4.8"
        transform="rotate(-45 341.856 632.759)"
      ></ellipse>
      <path
        fill="#ffe206"
        d="M657.67 431.13c.633-15.554-12.772-28.728-29.94-29.426s-31.597 11.346-32.229 26.9 11.302 19.088 28.47 19.785 33.068-1.706 33.7-17.26z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#fff"
        d="M636.068 493.39a9.572 9.572 0 11-18.836 3.43l-.003-.019c-.942-5.202 3.08-7.043 8.283-7.985s9.614-.627 10.556 4.575z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#ccc"
        d="M843 504.49H351a18 18 0 000 36h492a18 18 0 000-36z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#3f3d56"
        d="M806 350.49v155h-2v-155q0-8.054-.63-15.96c-.05-.67-.1-1.33-.16-2a200.662 200.662 0 00-31.2-91.02c-.43-.67-.86-1.33-1.3-2A201.969 201.969 0 00603 148.5c-.33-.01-.67-.01-1-.01s-.67 0-1 .01q-5.685.016-11.28.36a200.47 200.47 0 00-43.17 7.37 199.6 199.6 0 00-23.43 8.3 203.248 203.248 0 00-89.83 74.98c-.44.67-.87 1.33-1.3 2a200.662 200.662 0 00-31.2 91.02c-.06.67-.11 1.33-.16 2q-.63 7.906-.63 15.96v155h-2v-155q0-9.12.79-18.03a202.465 202.465 0 0132.25-93.15 204.174 204.174 0 01116.58-85.46 202.849 202.849 0 0143.02-7.04q5.64-.314 11.36-.32a204.228 204.228 0 01203.21 186.02q.78 8.88.79 17.98z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#2f2e41"
        d="M545.635 648.565c56.612.967 56.604 85.176-.001 86.135-56.61-.967-56.602-85.176.001-86.135z"
        transform="translate(-196 -146.49)"
      ></path>
      <path fill="#2f2e41" d="M330.01 578.941H343.094V602.383H330.01z"></path>
      <path fill="#2f2e41" d="M356.177 578.941H369.261V602.383H356.177z"></path>
      <path
        fill="#2f2e41"
        d="M536.913 745.058c14.138-.1 14.138 8.278 0 8.177-14.138.1-14.138-8.278 0-8.177z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#2f2e41"
        d="M563.08 744.513c14.138-.1 14.138 8.278 0 8.177-14.137.1-14.137-8.279 0-8.177z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#fff"
        d="M546.726 666.01c19.25.086 19.248 29.355-.001 29.438-19.249-.086-19.247-29.354 0-29.438z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#3f3d56"
        d="M546.726 675.823c6.366-.093 6.366 9.906 0 9.813-6.366.093-6.366-9.907 0-9.813z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#ccc"
        d="M503.859 651.581c-8.092-36.891 53.888-50.724 62.25-13.898 3.477 15.572-7.915 21.316-25.105 25.154s-33.668 4.318-37.145-11.256z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#2f2e41"
        d="M564.409 532.946c10.033-26.273 22.679-21.439 12.629 4.828-10.033 26.273-22.679 21.439-12.63-4.828z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#2f2e41"
        d="M488.405 560.983c10.023-55.726 93.143-42.228 85.022 13.8-10.022 55.724-93.143 42.226-85.022-13.8z"
        transform="translate(-196 -146.49)"
      ></path>
      <path fill="#2f2e41" d="M315.291 455.191H328.375V478.633H315.291z"></path>
      <path
        fill="#2f2e41"
        d="M341.458 455.191H354.54200000000003V478.633H341.458z"
      ></path>
      <path
        fill="#2f2e41"
        d="M522.194 621.307c14.137-.1 14.137 8.279 0 8.178-14.138.1-14.138-8.279 0-8.178z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#2f2e41"
        d="M548.361 620.762c14.138-.1 14.138 8.279 0 8.177-14.137.1-14.138-8.278 0-8.177z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#ffe206"
        d="M489.14 527.83c-8.092-36.89 53.888-50.724 62.25-13.898 3.477 15.572-7.915 21.316-25.105 25.155s-33.668 4.317-37.146-11.256z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#2f2e41"
        d="M462.952 538.67c3.044-8.19 43.332 10.918 38.912 18.457-3.043 8.19-43.332-10.918-38.912-18.456z"
        transform="translate(-196 -146.49)"
      ></path>
      <ellipse
        cx="668.205"
        cy="492.895"
        fill="#2f2e41"
        rx="6.594"
        ry="21.006"
        transform="rotate(-40.645 372.437 684.257)"
      ></ellipse>
      <ellipse
        cx="580.971"
        cy="492.895"
        fill="#2f2e41"
        rx="21.006"
        ry="6.594"
        transform="rotate(-49.355 323.558 632.938)"
      ></ellipse>
      <path
        fill="#e6e6e6"
        d="M959 282.493h-59a10 10 0 010-20v2a8 8 0 000 16h59zM898 335.493h-59a10 10 0 010-20v2a8 8 0 000 16h59zM975 387.493v-2a8 8 0 000-16h-59v-2h59a10 10 0 010 20zM994 205.493v-2a8 8 0 000-16h-59v-2h59a10 10 0 010 20zM902 224.493h-59a10 10 0 010-20v2a8 8 0 000 16h59zM326 414.493h-59a10 10 0 010-20v2a8 8 0 000 16h59zM357 448.493v-2a8 8 0 000-16h-59v-2h59a10 10 0 010 20zM265 467.493h-59a10 10 0 010-20v2a8 8 0 000 16h59zM330 303.493h-59a10 10 0 010-20v2a8 8 0 000 16h59zM361 337.493v-2a8 8 0 000-16h-59v-2h59a10 10 0 010 20zM269 356.493h-59a10 10 0 010-20v2a8 8 0 000 16h59zM755 611.493h-59a10 10 0 010-20v2a8 8 0 000 16h59zM821 665.493v-2a8 8 0 000-16h-59v-2h59a10 10 0 010 20zM701 675.493h-59a10 10 0 010-20v2a8 8 0 000 16h59zM487 670.493v-2a8 8 0 000-16h-59v-2h59a10 10 0 010 20zM309 695.493h-59a10 10 0 010-20v2a8 8 0 000 16h59z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#3f3d56"
        d="M706.886 753.493h-381a1 1 0 010-2h381a1 1 0 010 2z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#3f3d56"
        d="M382.53 752.723c-.77 1.77-3.35.24-2.59-1.51a100.86 100.86 0 007.17-25.9 109.443 109.443 0 00-3.45-45.66l-.48-1.6c-.38-1.22-.78-2.44-1.2-3.64a103.062 103.062 0 00-22.68-37.46c-1.33-1.4.79-3.52 2.12-2.12a103.417 103.417 0 0116.34 22.52 108.082 108.082 0 015.58 12.08c.63 1.61 1.22 3.24 1.78 4.87a114.24 114.24 0 015.81 30.06 107.162 107.162 0 01-8.4 48.36z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#3f3d56"
        d="M405.43 599.893q-4.995 18.285-9.98 36.57-5.175 18.93-10.33 37.84-.735 2.685-1.46 5.35l-.48-1.6c-.38-1.22-.78-2.44-1.2-3.64.45-1.66.9-3.32 1.36-4.98l1.86-6.81q8.67-31.77 17.34-63.53a1.5 1.5 0 012.89.8z"
        transform="translate(-196 -146.49)"
      ></path>
      <path
        fill="#ffe206"
        d="M343.743 604.261c-1.748 3.837-3.512 7.722-4.27 11.87s-.411 8.652 1.832 12.221a20.34 20.34 0 007.421 6.46c4.187 2.387 9.631 4.074 13.635 1.392 4.045-2.71 4.474-8.833 2.209-13.142s-6.476-7.199-10.582-9.813-7.325-4.681-9.988-8.756zM414.996 565.171c-4.006 1.316-8.058 2.652-11.62 4.908s-6.64 5.562-7.744 9.63a20.339 20.339 0 00.282 9.835c1.082 4.697 3.533 9.843 8.22 10.968 4.733 1.136 9.526-2.699 11.158-7.285s.902-9.64.04-14.431-1.523-8.559-.332-13.28zM343.116 651.743c1.112 4.066 2.244 8.18 4.318 11.851s5.222 6.91 9.23 8.217a20.34 20.34 0 009.837.212c4.745-.846 10.007-3.036 11.366-7.66 1.372-4.67-2.217-9.65-6.716-11.51s-9.583-1.384-14.412-.764-8.624 1.093-13.278-.333zM355.116 704.743c1.112 4.066 2.244 8.18 4.318 11.851s5.222 6.91 9.23 8.217a20.34 20.34 0 009.837.212c4.745-.846 10.007-3.036 11.366-7.66 1.372-4.67-2.217-9.65-6.716-11.51s-9.583-1.384-14.412-.764-8.624 1.093-13.278-.333zM420.884 669.743c-1.112 4.066-2.244 8.18-4.318 11.851s-5.222 6.91-9.23 8.217a20.34 20.34 0 01-9.837.212c-4.745-.846-10.007-3.036-11.366-7.66-1.372-4.67 2.217-9.65 6.716-11.51s9.583-1.384 14.412-.764 8.624 1.093 13.278-.333zM432.884 615.743c-1.112 4.066-2.244 8.18-4.318 11.851s-5.222 6.91-9.23 8.217a20.34 20.34 0 01-9.837.212c-4.745-.846-10.007-3.036-11.366-7.66-1.372-4.67 2.217-9.65 6.716-11.51s9.583-1.384 14.412-.764 8.624 1.093 13.278-.333z"
        transform="translate(-196 -146.49)"
      ></path>
    </svg>
  );
}
