import { useQuery } from "react-query";

import { useTreasuredClient } from "~/contexts/treasured-client";

function useUser(userId) {
  const client = useTreasuredClient();

  const { data: user } = useQuery(
    ["users", userId],
    () => client.users.getUser(userId),
    {
      enabled: !!userId,
    }
  );

  const fullName = user && `${user.firstName} ${user.lastName}`;

  // Deserialize exhibit theme
  if (user?.exhibits) {
    for (let i = 0; i < user.exhibits.length; i++) {
      if (!user.exhibits[i].theme || typeof user.exhibits[i].theme === "object")
        continue;
      user.exhibits[i].theme = JSON.parse(user.exhibits[i].theme);
    }
  }

  return { ...user, fullName };
}

export default useUser;
