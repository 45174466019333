import React from "react";
import {
  Button,
  HStack,
  Text,
  VStack,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  IconButton,
  MenuDivider,
  useColorMode,
  useColorModeValue,
  Icon,
} from "design-system";
import {
  MdAccountBox,
  MdExitToApp,
  MdExplore,
  MdLanguage,
  MdMoreVert,
  MdSearch,
} from "react-icons/md";
import Link from "next/link";
import { useRouter } from "next/router";
import PropTypes from "prop-types";

import useAuth from "~/hooks/useAuth";
import useUser from "~/hooks/useUser";

function MobileNavigationBarItem({
  children = "Item",
  icon = null,
  isActive = false,
  ...props
}) {
  return (
    <Button
      colorScheme={isActive ? "brand" : "gray"}
      borderRadius="none"
      h={16}
      variant="ghost"
      flex="1"
      isActive={isActive}
      {...props}
    >
      <VStack spacing={1}>
        {icon}
        <Text as="span" fontSize="sm">
          {children}
        </Text>
      </VStack>
    </Button>
  );
}

MobileNavigationBarItem.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  isActive: PropTypes.bool,
};

function MobileNavigationBarContainer({ children }) {
  const bg = useColorModeValue("white", "gray.700");
  return (
    <HStack as="nav" justify="stretch" bg={bg} borderTopWidth={1} spacing={0}>
      {children}
    </HStack>
  );
}

MobileNavigationBarContainer.propTypes = {
  children: PropTypes.node,
};

export function MobileNavigationBar() {
  const { currentUser, login, logout } = useAuth();
  const router = useRouter();
  const { exhibits } = useUser(currentUser?.id);
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <MobileNavigationBarContainer>
      <MobileNavigationBarItem
        icon={<Icon as={MdExplore} w={6} h={6} />}
        onClick={() => router.push("/explore")}
      >
        Explore
      </MobileNavigationBarItem>
      <MobileNavigationBarItem
        icon={<Icon as={MdSearch} w={6} h={6} />}
        onClick={() => router.push("/search")}
      >
        Search
      </MobileNavigationBarItem>
      {currentUser && (
        <MobileNavigationBarItem
          icon={<Icon as={MdLanguage} w={6} h={6} />}
          onClick={() => router.push("/worlds")}
        >
          Worlds
        </MobileNavigationBarItem>
      )}
      <Menu>
        <MenuButton
          as={IconButton}
          colorScheme="gray"
          variant="ghost"
          icon={<Icon as={MdMoreVert} w={6} h={6} />}
          borderRadius="none"
          w={16}
          h={16}
        />
        <MenuList>
          <Stack spacing={0} px={4}>
            <Text fontSize="sm" fontWeight="medium">
              Treasured
            </Text>
            <Text fontSize="sm" fontWeight="medium" color="gray.500">
              {process.env.NODE_ENV === "production"
                ? `Build ${process.env.RELEASE || "v0.0.0"}`
                : "Development Build"}
            </Text>
          </Stack>
          <MenuDivider />
          {currentUser && (
            <>
              <MenuGroup title="Exhibits">
                {exhibits?.map((e) => (
                  <Link key={e.id} href={`/exhibit/${e.id}`} passHref>
                    <MenuItem as="a">{e.title}</MenuItem>
                  </Link>
                ))}
              </MenuGroup>
              <MenuDivider />
            </>
          )}
          <Link href="/help" passHref>
            <MenuItem as="a">Help & Feedback</MenuItem>
          </Link>
          {currentUser && (
            <Link href="/settings" passHref>
              <MenuItem as="a">Settings</MenuItem>
            </Link>
          )}
          <MenuItem onClick={toggleColorMode}>
            Toggle {colorMode === "light" ? "dark" : "light"} mode
          </MenuItem>
          <MenuDivider />
          {currentUser ? (
            <MenuItem
              icon={<Icon as={MdExitToApp} w={6} h={6} />}
              onClick={logout}
            >
              Logout
            </MenuItem>
          ) : (
            <MenuItem
              icon={<Icon as={MdAccountBox} w={6} h={6} />}
              onClick={login}
            >
              Login
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </MobileNavigationBarContainer>
  );
}
