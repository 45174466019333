import { useQueryClient, useQuery, useMutation } from "react-query";

import { useTreasuredClient } from "~/contexts/treasured-client";
import useAuth from "./useAuth";

export function useMyWorlds() {
  const client = useTreasuredClient();
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();

  const { data: myWorlds } = useQuery(
    ["me", "worlds"],
    () => client.worlds.getWorlds(),
    {
      enabled: Boolean(currentUser),
    }
  );
  const { data: sharedWorlds } = useQuery(
    ["shared", "worlds"],
    () => client.worlds.getSharedWorlds(),
    {
      enabled: Boolean(currentUser),
    }
  );

  const { mutateAsync: createWorld, isLoading: isCreating } = useMutation(
    ({ world, coupon }) => client.worlds.createWorld(world, coupon),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["me", "worlds"]);
        queryClient.setQueryData(["worlds", data.id], data);
      },
    }
  );

  return { myWorlds, sharedWorlds, createWorld, isCreating };
}
